var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.userData === undefined ? 'div' : 'b-card', {
    tag: "component"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.userData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Error fetching user data ")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No user found with this user id. Check "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-users-list'
      }
    }
  }, [_vm._v(" User List ")]), _vm._v(" for other users. ")], 1)]), _vm.userData ? _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "UserIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v("Account")])];
      },
      proxy: true
    }], null, false, 819792000)
  }, [_c('user-edit-tab-account', {
    staticClass: "mt-2 pt-75",
    attrs: {
      "user-data": _vm.userData
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "InfoIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v("Information")])];
      },
      proxy: true
    }], null, false, 1675473746)
  }, [_c('user-edit-tab-information', {
    staticClass: "mt-2 pt-75"
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "Share2Icon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v("Social")])];
      },
      proxy: true
    }], null, false, 1914541044)
  }, [_c('user-edit-tab-social', {
    staticClass: "mt-2 pt-75"
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }