var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-media', {
    staticClass: "mb-2",
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-avatar', {
          ref: "previewEl",
          attrs: {
            "src": _vm.userData.avatar,
            "text": _vm.avatarText(_vm.userData.fullName),
            "variant": "light-".concat(_vm.resolveUserRoleVariant(_vm.userData.role)),
            "size": "90px",
            "rounded": ""
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h4', {
    staticClass: "mb-1"
  }, [_vm._v(" " + _vm._s(_vm.userData.fullName) + " ")]), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.refInputEl.click();
      }
    }
  }, [_c('input', {
    ref: "refInputEl",
    staticClass: "d-none",
    attrs: {
      "type": "file"
    },
    on: {
      "input": _vm.inputImageRenderer
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline"
  }, [_vm._v("Update")]), _c('feather-icon', {
    staticClass: "d-inline d-sm-none",
    attrs: {
      "icon": "EditIcon"
    }
  })], 1), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "outline-secondary"
    }
  }, [_c('span', {
    staticClass: "d-none d-sm-inline"
  }, [_vm._v("Remove")]), _c('feather-icon', {
    staticClass: "d-inline d-sm-none",
    attrs: {
      "icon": "TrashIcon"
    }
  })], 1)], 1)]), _c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Username",
      "label-for": "username"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "username"
    },
    model: {
      value: _vm.userData.username,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "username", $$v);
      },
      expression: "userData.username"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "full-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "full-name"
    },
    model: {
      value: _vm.userData.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "fullName", $$v);
      },
      expression: "userData.fullName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "email",
      "type": "email"
    },
    model: {
      value: _vm.userData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "email", $$v);
      },
      expression: "userData.email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "user-status"
    }
  }, [_c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.statusOptions,
      "reduce": function reduce(val) {
        return val.value;
      },
      "clearable": false,
      "input-id": "user-status"
    },
    model: {
      value: _vm.userData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "status", $$v);
      },
      expression: "userData.status"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "User Role",
      "label-for": "user-role"
    }
  }, [_c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.roleOptions,
      "reduce": function reduce(val) {
        return val.value;
      },
      "clearable": false,
      "input-id": "user-role"
    },
    model: {
      value: _vm.userData.role,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "role", $$v);
      },
      expression: "userData.role"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company",
      "label-for": "company"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "company"
    },
    model: {
      value: _vm.userData.company,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "company", $$v);
      },
      expression: "userData.company"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "border mt-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "p-1"
  }, [_c('b-card-title', {
    staticClass: "font-medium-2"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LockIcon",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Permission")])], 1)], 1), _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "striped": "",
      "responsive": "",
      "items": _vm.permissionsData
    },
    scopedSlots: _vm._u([{
      key: "cell(module)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": data.value
          }
        })];
      }
    }])
  })], 1), _c('b-button', {
    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
    attrs: {
      "variant": "primary",
      "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
    }
  }, [_vm._v(" Save Changes ")]), _c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "type": "reset",
      "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
    }
  }, [_vm._v(" Reset ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }