var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', [_c('b-row', [_vm._l(_vm.socialInputs, function (socialField) {
    return _c('b-col', {
      key: socialField.dataField,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": socialField.label,
        "label-for": socialField.dataField
      }
    }, [_c('b-input-group', {
      staticClass: "input-group-merge"
    }, [_c('b-input-group-prepend', {
      attrs: {
        "is-text": ""
      }
    }, [_c('feather-icon', {
      attrs: {
        "size": "16",
        "icon": socialField.icon
      }
    })], 1), _c('b-form-input', {
      attrs: {
        "id": socialField.dataField,
        "type": "url"
      },
      model: {
        value: _vm.userDataSocial[socialField.dataField],
        callback: function callback($$v) {
          _vm.$set(_vm.userDataSocial, socialField.dataField, $$v);
        },
        expression: "userDataSocial[socialField.dataField]"
      }
    })], 1)], 1)], 1);
  }), _c('b-col', {
    staticClass: "mt-2"
  }, [_c('b-button', {
    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
    attrs: {
      "variant": "primary",
      "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
    }
  }, [_vm._v(" Save Changes ")]), _c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
    }
  }, [_vm._v(" Reset ")])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }